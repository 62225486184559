import { browser } from '$app/env';
import { writable } from 'svelte/store'

export const wallet = writable(null);
export const account = writable(null);

if (browser) {
  wallet.subscribe((w) => {
    if (w) {
      if (browser)
        localStorage.setItem('colorglyph.io_wallet', JSON.stringify(w))
  
      accountLoad(w?.pubkey)
    }
  })

  if (localStorage.hasOwnProperty('colorglyph.io_wallet')) {
    const w = JSON.parse(localStorage.getItem('colorglyph.io_wallet'))
    wallet.set(w)
  }
}

export async function accountLoad(pubkey) {
  if (!pubkey || !browser)
    return

  const { Server } = await import('stellar-sdk')
  const server = new Server(import.meta.env.VITE_HORIZON_URL)

  return server
  .loadAccount(pubkey)
  .then((res) => account.set(res))
}